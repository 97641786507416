import React from "react";
import { useState } from "react";
import Loader from "../loader";
import Previous from "../../assets/images/previous.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Background from "../../assets/images/backgroundSubscription.png";
import Logo from "../../assets/images/easypaisa_logo.png";
import Coin from "../../assets/images/coin.png";
import { url } from "../../constants";
import { useEffect } from "react";
import {
  getSubscriptionTypeList,
  getSubscriptionUserDetails,
  createXsollaSubscriptionTransaction,
  epCreatePayment,
  epPaymentStatus,
  getRenwalOptOut,
  getRenwalConfirmation,
} from "../../apis";
import { func } from "../../logDetails";
import moment from "moment";
import { checkDobNstateConditions } from "../common";
import { ToastContainer, toast } from "react-toastify";
import DobNStates from "../../screens/dobnstate";
import { useTranslation, Trans } from "react-i18next";
import Cookies from 'js-cookie'
import Close from "../../assets/images/close.svg";
import SuccessImage from "../../assets/images/round_qualified.png";
import FailureImage from "../../assets/images/round_not_qualified.png";


import PremiumClose from "../../assets/images/cancel_premium.png"
import roller from "../../assets/images/rolling.gif";


import { async } from "q";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../utils/appEvents";
// import { callHandler, registerHandler } from "./jsbridge";
const SubscriptionUI = () => {
  const [loading, setLoading] = useState(false);
  const [tabTypes, setTabTypes] = useState([]);
  const [active, setActive] = useState('Monthly');
  const [premiumDetails, setPremiumDetails] = useState({});
  const [nonPremiumDeatils, setNonPremiumDeatils] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [premiumUser, setPremiumUser] = useState(
    JSON.parse(localStorage.getItem("premiumUser"))
  );
  const [benefits, setBenefits] = useState([]);
  const [token, setToken] = useState("");

  const [amount, setAmount] = useState(0);
  const [symbol, setSymbol] = useState("₹");
  const [paymentId, setPaymentId] = useState("");
  const [tagShowDobNstate, setTagShowDobNstate] = useState(false);
  const dobNstateScreenName = "subscription-buy-pack";
  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionId, setTransactionId] = useState('')
  const [createTimeTn, setCreateTimeTn] = useState('')
  const [showSucessPopup, setShowSucessPopup] = useState(false)
  const [showMessage, setShowMessage] = useState('')

  const [showPayNow, setPaynow] = useState(false)
  const userId = JSON.parse(localStorage.getItem("profileLite"))?.item?.user_id;
  const { t } = useTranslation()
  const currentLang = Cookies.get('i18next') || 'EN'

    const [isBusy, setIsBusy] = useState(false)

    const fetchSubscriptionDetails = async () => {
      try {
      setLoading(true);
      // console.log(searchParams.get('paymentTransactionID'))
      if (searchParams.get('paymentTransactionID'))
      {
          // alert(searchParams.get('paymentTransactionID'))
          setShowSucessPopup(true)
          setShowMessage(searchParams.get('paymentStatus'))
          let body = {
      
            paymentTransactionID:searchParams.get('paymentTransactionID'),
            result:searchParams.get('paymentStatus'),
            resultCode:searchParams.get('resultCode'),
            subscriptionId:localStorage.getItem("subscriptionId")
            }
          const res = await epPaymentStatus(body)
      }
        
      let res = null
     
        if (premiumUser) {
          res = await getSubscriptionUserDetails(userId);
          func(res, "SubscriptionUi 23");
          if (res?.success) {
            setPremiumDetails(res?.data[0]?.param);
            // func(moment(res?.data[0]?.param?.subscription?.startDate).format("Do MMMM YYYY"))
            setStartDate(
              moment(res?.data[0]?.param?.subscription?.startDate).format(
                "Do MMM YYYY"
              )
            );
            setEndDate(
              moment(res?.data[0]?.param?.subscription?.endDate).format(
                "Do MMM YYYY"
              )
            );
            //  setBenefits(res?.data[0]?.param?.benefits);
             
          }
          setLoading(false);
        }
  
        // if (!premiumUser || res?.data[0]?.param?.isRenewRequired) {
          // console.log("cuntryid", JSON.parse(localStorage.getItem('countryId')), localStorage.getItem('companyId'))
          let payload = {
            country: JSON.parse(localStorage.getItem("userCountryObj"))?._id,
            company: process.env.REACT_APP_COMPANY_ID,
          };
          const types = await getSubscriptionTypeList(payload);
          // console.log(types.data)
          if (types?.success) {
            setNonPremiumDeatils(types?.data[0]?.param?.data);
            func(types?.data[0]?.param?.data, "types 47");
            let ty = [];
            // console.log(types?.data[0])
            types?.data[0]?.param?.data.forEach((element) => {
              // ty.push(element?.packs[0]?.packName)
              // console.log(element?.packs[0]?.packName)
              if (element?.packs[2]?.packName == "Monthly") {
                // console.log(element?.benefits);
  
                setActive("Monthly");
                setBenefits(element?.benefits);
                setAmount(element?.packs[2]?.amount);
                setSymbol(element?.currencyID?.symbol);
                setPaymentId(element?.packs[2]?._id);
                localStorage.removeItem("subscriptionId")
                localStorage.removeItem("coinStoreId")
                localStorage.setItem("subscriptionId", element?.packs[2]?._id)
              }
              else{
                setActive(element?.packs[0]?.packName);
                setBenefits(element?.benefits);
                setAmount(element?.packs[0]?.amount);
                setSymbol(element?.currencyID?.symbol);
                setPaymentId(element?.packs[0]?._id);
                localStorage.removeItem("subscriptionId")
                localStorage.removeItem("coinStoreId")
                localStorage.setItem("subscriptionId", element?.packs[0]?._id)
              }
            });
            // console.log("types?.packs", types?.data[0]?.param?.data[0]?.packs);
            setTabTypes(types?.data[0]?.param?.data[0]?.packs);
            setLoading(false);
          }
        // }
      } catch (error) {
        func("SubscriptionUi 27", error);
      }
  
     
    }
  useEffect(
    () =>{
      fetchSubscriptionDetails()
    }, []);

  const navigate = useNavigate();
  const onClickTab = (type, ind) => {
    setActive(type);
    // console.log(nonPremiumDeatils, "selected pack");
    setSymbol(nonPremiumDeatils[0]?.currencyID?.symbol);
    setAmount(nonPremiumDeatils[0]?.packs[ind]?.amount);
    setBenefits(nonPremiumDeatils[0]?.benefits);
    setPaymentId(nonPremiumDeatils[0]?.packs[ind]?._id);
    localStorage.setItem("subscriptionId", nonPremiumDeatils[0]?.packs[ind]?._id)
  };
  const cancelDobNstate = () => {
    setTagShowDobNstate(false);
  };
  const submitDobnState = (tag) => {
    // console.log(tag)
    if (tag) {
    } else {
      setTagShowDobNstate(false);
      // window.location.href = "/payment-gateway-web/" + paymentId;
      movePayment()
    }
  };
  //   const buyPackHandler = () => {
  //     if (checkDobNstateConditions(dobNstateScreenName)) {
  //       setTagShowDobNstate(true);
  //     } else {
  //       window.location.href = "/payment-gateway-web/" + paymentId;
  //     }
  //   };
  const movePayment = async () => {
    setLoading(true);

    let payload = {
      subscriptionId: paymentId,
      isMobile: false,
    };

    // let data = await createXsollaSubscriptionTransaction(payload);
    // if (data?.success) {
    //   let transactionUniqueID = data?.data?.transactionUniqueID;
    //   let body = {
    //     transactionUniqueID: transactionUniqueID,
    //     subscriptionId: paymentId,
    //     isMobile: false,
    //   };
    //   localStorage.setItem("body", JSON.stringify(body));
    //   setToken(data?.data?.token);
    //   setLoading(false);
    //   let url = `https://secure.xsolla.com/paystation4/?token=${data?.data?.token}`;
    //   window.location.href = url;
    // } else {
    //   setLoading(false);
    // }
  }
  const buyPackHandler = async () => {
    // alert(paymentId);
    if (checkDobNstateConditions(dobNstateScreenName)) {
      setTagShowDobNstate(true);
    } else {
      movePayment()
    }
  };

  const redirectHandler = (item) => {
    // navigate(`/${item}`);
 // console.log(item,"^^^^^^^^^^^^^redirection")
  item=item.toLowerCase()
    switch (item) {
      case "home":
        navigate(`/`);
        break;
      case "leaderboard":
        navigate(`/leader-board`);
        break;
      case "crm":
        navigate(`/customer-care`);
        break;
      case "profile":
        navigate(`/profile`);
        break;
      default:
        break;
    }
  };

  const [message, setMessage] = useState('')
  const [resultCode, setResultCode] = useState('')
  const handlerBuy = async () => {
    setIsBusy(true)
    try {
      localStorage.removeItem('paymentTransactionID')
      var body = {
        paymentOrderTitle: paymentId,
        amount: amount
      }
      const res = await epCreatePayment(body)
      // console.log("res>>>>", res?.data)
      
      if (res?.data?.data?.paymentTransactionID) {
        setTransactionId(res?.data?.data?.paymentTransactionID)
    localStorage.setItem('paymentTransactionID',res?.data?.data?.paymentTransactionID)

        setCreateTimeTn(res?.data?.data?.paymentCreateTime)
        setIsBusy(false)
        // setPaynow(true)
        setMessage(res?.data?.data?.result?.resultMessage)
    setResultCode(res?.data?.data?.result?.resultStatus)
    if(localStorage.getItem('emode') == 'off'){
      // window.location.href = `/xsolla-status?paymentStatus=S&&paymentTransactionID=${res?.data?.data?.paymentTransactionID}&&resultCode=9000`
      console.log(">>>", "enterrr")
    }
  else
    window['callCoinStore'](res?.data?.data?.paymentTransactionID)
      }
      
  else{
    window.alert(JSON.stringify(res?.data))
    setIsBusy(false)
    // window.location.href = `/xsolla-status?paymentStatus=F&&paymentTransactionID=null&&resultCode=4000`
  }

    }
    catch (error) {
      // console.log(error)
      setIsBusy(false)
      toast.error(error)
    }

    // console.log("clicked")
    // registerHandler()
    // my.alert({
    //   title: 'Test Alert!!',
    //   content: window.navigator.userAgent,
    //   buttonText: 'Alert Button',
    //   success: function (res) {
    //     my.alert({
    //       content: 'success!' + JSON.stringify(res),
    //     });
    //   },
    //   fail: function () {
    //     my.alert({
    //       content: 'fail!',
    //     });
    //   },
    //   complete: function () {
    //     my.alert({
    //       content: 'complete!',
    //     });
    //   },
    // });
    //   console.log(/MiniProgram/.test(navigator.userAgent))

    //  my.createPayment({
    //   scopes: ['USER_NAME', 'USER_BIRTHDAY'],
    //   success: (res) => {
    //     my.alert({
    //       content: res.authCode,
    //     });
    //   },
    //   fail: (res) => {
    //       console.log(res)
    //   },
    // });
    // my.createPayment('12309458902409')
  }
  localStorage.setItem("platform", "WebApp");
const handlerUnsubscription = async()=>{
  // const result = await getRenwalOptOut()
  let body = {isRenew:"true"}
  const result = await getRenwalConfirmation(body)
  console.log(result)
    

}
  return (
    <div
      className="inner-wrap d-flex flex-column"
      style={{ height: "100vh", overflow: "hidden", padding: "0" }}
    >
      {loading === true ? (
        <Loader />
      ) : (
        <>
          <ToastContainer />
          {tagShowDobNstate === true ? (
            <DobNStates
              submitDobNstate={submitDobnState}
              cancelDobNstate={cancelDobNstate}
              screen={dobNstateScreenName}
            />
          ) : (
            ""
          )}
          <div
            className="body mCustomScrollbar _mCS_1 "
            style={{
              borderRadius: "0",
              padding: "0",
              // paddingBottom: "60px",
            }}
          >
            <div
              className="all_sub_screens_div_related_to_ads"
              style={{ position: "relative" }}
            >
              <div
                className="back-btn subs-back"

                onClick={() => navigate('/more')}
              >
                <a>
                  <img src={Previous} alt="" />
                </a>
              </div>
              <div className="subImage">
                <div className="ps_header">
                  <div className="ps_logo">
                    <img src={Logo} />
                  </div>
                  <div className="ps_name">{t('tournaments.premium')}</div>
                </div>
                {premiumUser ? (
                  <>
                    {/* <div
                      className="ps_renew"
                      style={{
                        background: "#1c1c1ecc",
                        border: "1px solid #c5a260",
                      }}
                    >
                      <div
                        className="ps_benefits_text"
                        style={{ padding: "10px 0" }}
                      >
                        <div className="layer1">
                          <div className="ps_badge">
                            <img
                              src={
                                url.imageUrl +
                                premiumDetails?.userLevelIcon?.featuredImage
                                  ?.default
                              }
                              style={{ width: "40px", height: "40px" }}
                            />
                          </div>
                        </div>
                        <div className="layer2 renew_layer">
                          <div>
                            <h2>{premiumDetails?.gamerjiName}</h2>
                            <p className="renew_font">
                              {t('premium.premium_since')}: {startDate}
                            </p>
                            <p className="renew_font">{t('premium.expires_on')}: {endDate}</p>
                          </div>

                          {premiumDetails?.isRenewRequired ? (
                            <button className="renew_font">{t('premium.renew_now')}</button>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div> */}
                    {premiumDetails?.savedCurrency ? (
                      premiumDetails?.savedCurrency.map((item, ind) => (
                        <div
                          className="ps_renew"
                          style={{ background: "#1e1e1e" }}
                          key={ind}
                        >
                          <div
                            className="ps_benefits_text"
                            style={{ padding: "10px 15px" }}
                          >
                            {/* <div className="layer1">
                             
                            </div> */}
                            <div className="layer">
                              <h2>
                                {/* {item?.currencyDetails?.code[0].toUpperCase() +
                                  item?.currencyDetails?.code.slice(1)}{" "} */}
                                {t('premium.you_saved')}
                              </h2>

                              <button style={{ cursor: 'auto' }}>
                                <img
                                  src={
                                    url?.imageUrl +
                                    item?.currencyDetails?.img?.default
                                  }
                                  style={currentLang?.toLowerCase() == "ar" ? { width: "15px", height: "15px", marginLeft: '10px' } : { width: "15px", height: "15px", marginRight: '10px' }}
                                />
                                {item?.totalSavedAmount}
                              </button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
                {/* {console.log("benefits", benefits)} */}
                <div style={{ height: '80%' }}>
                  <div className="ps_section">
                    <div className="ps_heading">
                      <div className="ps_rect ps_rect1"></div>
                      <h2>{t('premium.benefits')}</h2>
                      <div className="ps_rect ps_rect2"></div>
                    </div>
                    <div className="ps_benefits">
                      {benefits ? (
                        benefits.map((item, ind) => (
                          <div className="ps_benefits_text" key={ind}>
                            <div className="layer1">
                              <img src={url?.imageUrl + item?.img?.default} />
                            </div>
                            <div className="layer2">
                              <h2>{item?.title}</h2>
                              <p>{item?.description}</p>
                            </div>
                            {/* {console.log("redirectionScreenLink",item?.redirectionScreenLink)} */}
                            {premiumUser && item?.redirectionScreenLink && item?.redirectionScreenLink!=="NA" && item?.redirectionScreenLink!=="N/A" && item?.redirectionScreenLink!==""? (
                              <span
                                className="icon"
                                style={{ color: "#c5a260", cursor: 'pointer' }}
                                onClick={() =>
                                  redirectHandler(item?.redirectionScreenLink)
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="21.493"
                                  height="18.154"
                                  viewBox="0 0 21.493 18.154"
                                >
                                  <g
                                    id="ico"
                                    transform="translate(21.493 18.151) rotate(-180)"
                                  >
                                    <path
                                      id="union"
                                      d="M8.389,17.858l-7.9-7.9A1,1,0,0,1,0,9.1V9.085a1,1,0,0,1,.147-.523A1,1,0,0,1,.3,8.36l.011-.011a1.01,1.01,0,0,1,.1-.091L8.389.29A1,1,0,0,1,9.8.29L9.814.3a1,1,0,0,1,0,1.415L3.442,8.086H20.493a1,1,0,0,1,1,1V9.1a1,1,0,0,1-1,1H3.48l6.334,6.333a1,1,0,0,1,0,1.415l-.011.01a1,1,0,0,1-1.414,0Z"
                                      transform="translate(0 0)"
                                      fill="currentColor"
                                    />
                                  </g>
                                </svg>
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                {premiumDetails?.subscription?.isPremium? (
                  <>
                  
                  </>
                ) :
                // premiumDetails?.subscription?.isPremium ? 
                // <>
                // <div className="ps_button mx-auto mb-4" >
                //       <button disabled={isBusy} onClick={handlerUnsubscription}>
                //         {/* {t('premium.buy_for', { amount: amount })} */}
                //        Subscribe
                //         {
                //           isBusy &&
                //           <img src={roller} alt="" style={{ maxWidth: 30, marginLeft:'10px' }} />
                //         }
                //       </button>
                //     </div>
                // </>
                // :
                (

                  <div className="ps_footer">
                    {func("tbatypes 216", tabTypes)}


                    {/* <button onClick={handlerBuy}>
                      check authcode
                    </button> */}
                    <div className="ps_buttonGroup d-flex justify-content-between">
                      {tabTypes?.map((item, ind) => (
                        <button
                          className={` ${active === item?.packName ? "active" : ""
                            }`}
                          onClick={() => {onClickTab(item?.packName, ind); eventTracking(events.subcription_plan,{EP_PlanAmount:amount});}}
                        >
                          {item?.packName}
                        </button>
                      ))}
                    </div>
                    {/* {console.log(symbol, amount)} */}
                    <div className="ps_button" >
                      <button disabled={isBusy} onClick={handlerBuy}>
                        {/* {t('premium.buy_for', { amount: amount })} */}
                        BUY FOR PKR {amount}
                        {
                          isBusy &&
                          <img src={roller} alt="" style={{ maxWidth: 30, marginLeft:'10px' }} />
                        }
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      {showPayNow === true && (
        <div className="tc_popup_bg">
          <div
            className={`custom-popup  confirm custom_scroll join show premium_active rules`}
            id="contact_popup"
          >

            <div className={`popup-head  premium_active`}>
              {/* <marquee> */}
              <h3>  <div className='ps_name'>Confirm Popup</div> </h3>
              {/* </marquee> */}

              <button
                className="close-popup"
                onClick={() => setPaynow(false)}
              >
                <img src={PremiumClose} alt="" />
              </button>
            </div>

            <div className="ps_popup_main">
              <div className="ps_popup_p">
                <p>Transaction Details</p></div>
              <p className="text-white">Transaction Id: {transactionId}</p>
              <p className="text-white">Time: {createTimeTn}</p>
              {
               resultCode == 'F' ?
               <>
               <p className="text-white">{message}</p>
               <p>Please try again!</p>
               <button className={`btn premium_active_btn`} onClick={()=> {
                 window.location.href = `/xsolla-status?paymentStatus=F&&paymentTransactionID=${transactionId}&&resultCode=4000`
               }}>
               {/* <div dangerouslySetInnerHTML={{ __html: `<a  onClick="mp.createPayment(${transactionId})">Cancel${coinAmount}</a>` }} /> */}
               Cancel

             </button>
             </>
             :
             <>
             {/* <p className="text-white">{message}</p> */}
              <button className={`btn premium_active_btn`}>
                <div dangerouslySetInnerHTML={{ __html: `<a  onClick="mp.createPayment(${transactionId})"> Pay Now ${symbol} ${amount}</a>` }} />

              </button>
              </>
}
              {/* <p>{transactionId}</p> */}
            </div>

          </div>
        </div>
      )}
      {showSucessPopup === true && (
        <div className="tc_popup_bg">
          <div className={`popup-head  premium_active`}>

            <button
              className="close-popup"
              style={{ bottom: '95%' }}
              onClick={() => {
                setShowSucessPopup(false)
                window.location.href = '/'
              }
            }
            >
              <img src={PremiumClose} alt="" />
            </button>
          </div>
          <div className=" d-flex flex-column justify-content-center align-items-center" style={{ height: '80%' }}>
            {
              showMessage == 'S' ? <>
               <img style={{ width: '10rem' }} src={SuccessImage} />
            <p className="text-white mt-2">Payment Successful</p>
</>
:
<>
<img style={{ width: '10rem' }} src={FailureImage} />
<p className="text-white mt-2">Payment Failed</p>
</>

            }
           
          </div>
        </div>
      )}
    </div>
  );
};

export default SubscriptionUI;
